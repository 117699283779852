<template>
  <div>
    <CIDataTable
      :vuex="vuex"
      :settings="settings"
      :route="route"
      model="products"
      @uploadFromCSV="uploadFromCSV"
    />
    <Modal
      :modalShow="isShowModal"
      :messageGeneral="modalMessage"
      :title="settings.page.csvPopupTitle"
      v-on:toggle-modal="isShowModal = false"
      :isSuccessButton="false"
    />
  </div>
</template>
<script>
  import { products } from '@/config/descriptions/products';
  import { productsTexts } from '@/config/pageTexts/products.json';
  import axios from 'axios';
  import Modal from "@/shared/UI/Modal.vue";

  export default {
    name: 'ProductsTable',
    components: { Modal },
    data() {
      return {
        vuex: {
          getter: {
            theme: 'theme/theme',
            data: 'products/products',
            fields: 'products/fields',
            sortableFields: 'products/sortableFields',
            pages: 'products/pages',
            loading: 'products/loading',
            resultCount: 'products/resultCount',
            totalRecords: 'products/totalRecords',
            originalFields: 'products/originalFields',
            searchableAllFields: 'products/searchableAllFields',
            success: 'products/success',
            message: 'products/message',
          },
          actions: {
            fields: 'products/handleFields',
            delete: 'products/handleDeleteProducts',
            reset: 'products/handleClearFilter',
            query: 'products/handleGetProductsByQuery',
          },
        },
        settings: {
          describe: { ...products },
          page: { ...productsTexts },
        },
        route: {
          create: 'CreateProduct',
          edit: 'EditProduct',
          preview: 'PreviewProduct',
        },
        isShowModal: false,
        modalMessage: '',
      };
    },
    methods: {
      async uploadFromCSV(file) {
        const token = localStorage.getItem('vue-token')

        const path = 'https://api.ziqni.com/upload-products-csv-file'

        const formData = new FormData()
        formData.append('productsFile', file, file.name)

        await axios.post(path, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          }
        })
          .then(result => {
            if (result.data.results && result.data.results.length) {
              if (result.data.results[0].result === 'Updated') {
                this.modalMessage = this.settings.page.csvRecordsUpdated + ' ' + result.data.meta.totalRecords;
              } else {
                this.modalMessage = this.settings.page.csvRecordsCreated + ' ' + result.data.meta.totalRecords;
              }
              this.isShowModal = true;
            }
          })
          .catch(error => console.error(error))
      },
    }
  };
</script>